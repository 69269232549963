import React from 'react';

import './Footer.css'

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <p className='footer__copywright'>&copy; 2023 WiFi, Money & Travel. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
