import React, { createContext, useState, useContext, ReactNode } from 'react';

import './OverlayContext.css'

type OverlayContextType = {
  showOverlay: (message: string) => void;
  hideOverlay: () => void;
  overlayMessage: string | null;
};

const OverlayContext = createContext<OverlayContextType | undefined>(undefined);

export const useOverlay = () => {
  const context = useContext(OverlayContext);
  if (!context) {
    throw new Error('useOverlay must be used within an OverlayProvider');
  }
  return context;
};

interface OverlayProps {
  children: ReactNode
}

export const OverlayProvider: React.FC<OverlayProps> = ({ children }) => {
  const [overlayMessage, setOverlayMessage] = useState<string | null>(null);

  const showOverlay = (message: string) => {
    setOverlayMessage(message);
  };

  const hideOverlay = () => {
    setOverlayMessage(null);
  };

  return (
    <OverlayContext.Provider value={{ showOverlay, hideOverlay, overlayMessage }}>
      {children}
      {overlayMessage && (
        <div className="overlay">
          <div className="overlay__content">
            <span className="overlay__close" onClick={hideOverlay}>
              &times;
            </span>
            <div className="overlay__message">{overlayMessage}</div>
            <button className="overlay__button" onClick={hideOverlay}>
              Close
            </button>
          </div>
        </div>
      )}
    </OverlayContext.Provider>
  );
};
