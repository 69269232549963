import React from "react";
import EmailForm from "../EmailForm/EmailForm";

import "./Hero.css";

const Hero: React.FC = () => {
  return (
    <section className="hero">
      <div className="hero-content">
        <div className="hero__left-col">
          <h2 className="hero__title">
            Unlock the <span className="highlight">Secrets</span> to Working
            Remotely and Traveling the World
          </h2>
          <p className="hero__subheading">
            Your journey to a limitless world starts here
          </p>
          <p className="hero__disclaimer">
            Once you read this, there is no turning back...
          </p>
          <div className="hero__description-block">
            <p>
              Imagine waking up in a new city, with the freedom to work from
              anywhere. Our free guide is your passport to a life filled with
              excitement, purpose, and endless <span className="highlight">possibilities</span>
            </p>
            <p>
              Download our free guide and get a sneak peek into how you can land
              a remote job, earn money online, and explore the globe.
            </p>
          </div>
        </div>
        <div className="hero__right-col">
          <img src="book-preview.png" alt="book cover" />
          <EmailForm />
        </div>
      </div>
    </section>
  );
};

export default Hero;
