import React from 'react';

import './Header.css';


const Header: React.FC = () => {
  return (
    <header className="header">
      <div className='header__wrapper'>
      <h1 className='header__title'>WiFi Money and travel: How to live and work anywhere in the world</h1>
      </div>
    </header>
  );
}

export default Header;
