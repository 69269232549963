import React from "react";

import "./Testimonials.css";

const Testimonials: React.FC = () => {
  const testimonials = [
    {
      name: "Alex T.",
      feedback:
        "This guide changed the way I approach remote work. A must-read!",
    },
    {
      name: "Maria S.",
      feedback:
        "I never thought it was possible until I read this guide. Within weeks, I landed my dream remote job and booked my first flight!",
    },
    {
      name: "John D.",
      feedback:
        "The free guide opened my eyes, but the full ebook? It completely transformed my life. I'm now a digital nomad, and I've never been happier",
    },
  ];

  return (
    <section className="testimonials">
      <h2 className="testimonials__title">
        What Readers Are <span className="highlight">Saying</span>
      </h2>
      <div className="testimonials-container">
        {testimonials.map((testi, index) => (
          <div className="testimonial" key={index}>
            <p>"{testi.feedback}"</p>
            <h4 className="testimonial__name">- {testi.name}</h4>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
