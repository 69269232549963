import React from "react";

import "./FunnelTeaser.css";

const FunnelTeaser: React.FC = () => {
  return (
    <section className="funnel-teaser">
      <h2>Your Journey Starts <span className="highlight">Here</span></h2>
      <ol className="funnel-teaser__list">
        <li>
        <div className="funnel-teaser__list-wrapper">
          <div className="funnel-teaser__icon" />
          <h3>Step 1: Establish Your Plan (Free Guide)</h3>
          </div>
          <p>
            Identify your objectives and outline actionable steps to kickstart
            your nomadic lifestyle.
          </p>
        </li>
        <li>
        <div className="funnel-teaser__list-wrapper">
          <div className="funnel-teaser__icon" />
          <h3>Step 2: Boots on the Ground (Full Ebook)</h3>
          </div>
          <p>
            Learn how to execute your plan, from finding accommodation to
            building a social network in your new destination.
          </p>
        </li>
        <li>
          <div className="funnel-teaser__list-wrapper">
          <div className="funnel-teaser__icon" />
          <h3>Step 3: Define Your Future (Full Ebook)</h3>
          </div>
          <p>
            Develop a long-term strategy to sustain and evolve your WiFi
            lifestyle, ensuring it continues to meet your needs.
          </p>
        </li>
      </ol>
      <p className="funnel-teaser__exit">
        Start with the <span className="highlight">FREE</span> guide today and embark on the journey to freedom and
        adventure.
      </p>
    </section>
  );
};

export default FunnelTeaser;
