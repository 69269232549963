import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import './CheckoutPage.css'

const CheckoutPage: React.FC = () => {
  const [productURL, setProductURL] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const redirectURL = encodeURIComponent(`${window.location.origin}/thank-you`);
      const gumroadProductURL = `https://doxxdude.gumroad.com/l/wifi-money-and-travel?success_url=${redirectURL}`;

      setProductURL(gumroadProductURL);
    }
  }, []);

  return (
    <>
      <Header />
      <div className="gumroad-product-embed">
      {productURL ? (
          <a href={productURL}>
            Loading...
          </a>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <Footer />
    </>
  );
};

export default CheckoutPage;
