import React from "react";

import './StatsBanner.css'

const StatsBanner: React.FC = () => {
  return (
    <section className="stats-section">
        <div className="stats-row">
          <div className="stat-item">
            <div className="number-wrap">
              <span className="number">10</span>
              <span className="suffix">+</span>
            </div>
            <span className="text-below">Countries Explored</span>
          </div>
          <div className="stat-item">
            <div className="number-wrap">
              <span className="number">5 years</span>
              <span className="suffix">+</span>
            </div>
            <span className="text-below">Working and living fully remote</span>
          </div>
          <div className="stat-item">
            <div className="number-wrap">
              <span className="number">500</span>
              <span className="suffix">+</span>
            </div>
            <span className="text-below">Insider Secrets</span>
          </div>
          <div className="stat-item">
            <div className="number-wrap">
              <span className="number">24/7</span>
            </div>
            <span className="text-below">Global Community</span>
          </div>
        </div>
    </section>
  );
};

export default StatsBanner;
