import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ThankYouPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isValidPurchase, setIsValidPurchase] = useState<boolean | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const saleId = params.get("sale_id");

    if (saleId) {
      // Send sale_id to serverless function to verify purchase
      fetch("/.netlify/functions/verifyPurchase", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ sale_id: saleId }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.valid) {
            setIsValidPurchase(true);
          } else {
            setIsValidPurchase(false);
            // Optionally redirect or show error
            navigate("/unauthorized");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsValidPurchase(false);
          navigate("/unauthorized");
        });
    } else {
      // No sale_id present
      setIsValidPurchase(false);
      navigate("/unauthorized");
    }
  }, [location, navigate]);

  if (isValidPurchase === null) {
    // Show a loading state while verifying
    return <div>Verifying your purchase...</div>;
  }

  if (isValidPurchase) {
    // Render the thank-you page content
    return (
      <div>
        <h1>Thank you for your purchase!</h1>
        <p>We've sent a confirmation to your email.</p>
        {/* Additional content */}
      </div>
    );
  } else {
    // This block may never be reached due to redirects
    return null;
  }
};

export default ThankYouPage;
