import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Testimonials from "../../components/Testimonials/Testimonials";
import Features from "../../components/Features/Features";
import CtaSection from "../../components/CtaSection/CtaSection";
import Hero from "../../components/Hero/Hero";
import StatsBanner from "../../components/StatsBanner/StatsBanner";
import FunnelTeaser from "../../components/FunnelTeaser/FunnelTeaser";
import Faq from "../../components/Faq/Faq";
import PainPoints from "../../components/PainPoints/PainPoints";

import "./LandingPage.css";

const LandingPage: React.FC = () => {
  return (
    <div className="landing-page">
      <Header />
      <Hero />
      <Features />
      <StatsBanner />
      <Testimonials />
      <CtaSection />
      <PainPoints />
      <FunnelTeaser />
      <Faq />
      <CtaSection
        title="Don't Let Opportunity Pass You By"
        body="<p>Every great adventure begins with a single step.<br/><br/> Take yours now and unlock a world of endless possibilities.</p>"
        ctaText="Yes, I Want My Free Guide!"
      />
      <Footer />
    </div>
  );
};

export default LandingPage;
