import React from "react";

import "./Features.css";

const Features: React.FC = () => {
  const features = [
    {
      title: "Uncover Hidden Opportunities",
      description:
        "Dive into industries and roles you never knew could be done remotely",
    },
    {
      title: "Avoid Common Pitfalls",
      description:
        "Discover the secrets to avoiding common mistakes that keep others stuck, why generic advice like 'follow your passion' isn't enough, and how to make conscious decisions aligned with your goals",
    },
    {
      title: "Craft Your Unique Path",
      description:
        "Design a personalized roadmap that aligns with your deepest desires and sets you on an unstoppable journey",
    },
  ];

  return (
    <section className="features">
      <h2 className="features__title">What You'll Learn In The <span className="highlight">Free</span> Guide</h2>
      <div className="features-container">
        {features.map((feat, index) => (
          <li className="feature" key={index}>
            <div className="feature__title">
              <div className="features__icon" />
              <h3> {feat.title}</h3>
            </div>
            <p className="feature__description">{feat.description}</p>
          </li>
        ))}
      </div>
    </section>
  );
}

export default Features;
