import React from "react";
import "./Button.css";

interface ButtonProps {
  onClickHandler: () => void;
  type?: "submit" | "reset" | "button" | undefined;
  disabled?: boolean;
  buttonText?: any;
}

const Button: React.FC<ButtonProps> = ({
  onClickHandler,
  buttonText = "Get Free Guide",
  type,
  disabled,
}) => {
  return (
    <>
      <button
        disabled={disabled}
        onClick={() => onClickHandler()}
        type={type}
        className="main"
      >
        {buttonText}
      </button>
    </>
  );
};

export default Button;
