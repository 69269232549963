import Button from "../Button/Button";
import "./CtaSection.css";

interface CTASectionProps {
  title?: string;
  body?: string;
  ctaText?: string;
}

const CtaSection: React.FC<CTASectionProps> = ({
  title = "Ready to Start Your Adventure?",
  body = `<p>
        Join thousands who have transformed their lives with remote work and travel.
      </p>`,
  ctaText = "Get Free Guide",
}) => {
  return (
    <section className="call-to-action">
      <h2>{title}</h2>
      <div dangerouslySetInnerHTML={{ __html: body }} />
      <Button
        onClickHandler={() => window.scrollTo(0, 0)}
        buttonText={ctaText}
      />
    </section>
  );
};

export default CtaSection;
