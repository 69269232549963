import React from "react";

import "./PainPoints.css";

const PainPoints: React.FC = () => {
  return (
    <section className="pain-points">
      <h2>Break Free from What's Holding You Back</h2>
      <div className="pain-points__list">
        <div className="pain-point__list-item">
          <h3>Feeling Trapped in a 9-to-5 Job?</h3>
          <div className="pain-point__issue-icon">✖</div>
          <p className="pain-point__issue-description">
            Learn how to transition smoothly into remote work without
            sacrificing income or career growth.
          </p>
        </div>
        <div className="pain-point__list-item">
          <h3>Unsure How to Balance Work and Travel?</h3>
          <div className="pain-point__issue-icon">✖</div>
          <p className="pain-point__issue-description">
            Get practical advice on managing time zones, communication, and
            productivity while on the move.
          </p>
        </div>
        <div className="pain-point__list-item">
          <h3>Feeling Alone In  Your Aspirations?</h3>
          <div className="pain-point__issue-icon">✖</div>
          <p className="pain-point__issue-description">
            Discover ways to build a global network and stay connected with
            like-minded professionals.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PainPoints;
