import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
import ThankYouPage from "./pages/ThankYouPage/ThankYouPage";
import Unauthorized from "./pages/Unauthorized/Unauthorized";

import { OverlayProvider } from "./contexts/OverlayContext/OverlayContext";

import "./App.css";

const App: React.FC = () => {
  return (
    <OverlayProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
      </Router>
    </OverlayProvider>
  );
};

export default App;
