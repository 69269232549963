import React, { useState } from "react";
import "./Faq.css";

interface FaqItemProps {
  question: string;
  answer: string;
}

const FaqItem: React.FC<FaqItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`faq-item ${isOpen ? "open" : ""}`}>
      <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
        <span className="faq-toggle">{isOpen ? "-" : "+"}</span>
        <span>{question}</span>
      </div>
      {isOpen && (
        <div className="faq-answer">
          <div
            dangerouslySetInnerHTML={{
              __html: answer,
            }}
          />
        </div>
      )}
    </div>
  );
};

const Faq = () => {
  const faqs = [
    {
      question: "What makes this free guide different from other resources?",
      answer:
        "<p>Our guide focuses on creating a personalized plan that aligns with your unique objectives, rather than offering generic advice.</p>",
    },
    {
      question: "Do I need any prior experience to benefit from this guide?",
      answer:
        "<p>No experience needed! Whether you're just starting or looking to enhance your journey, our guide meets you where you are.</p>",
    },
    {
      question: "How does the free guide relate to the full ebook?",
      answer: `<p>The free guide kickstarts your journey, providing essential steps to get you moving. The full ebook dives deeper, equipping you with advanced strategies for long-term success.</p>`,
    },
  ];

  return (
    <section className="faq-section">
      <h2>Frequently Asked Questions</h2>
      {faqs.map((faq, index) => (
        <FaqItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </section>
  );
};

export default Faq;
